<template>
  <div ref="slideForm" class="slide_form">
    <div class="newsletter">
      
    <div class="container">
      <div class="row">
        <div class="crose_button" v-on:click="closeAppPopup">
          <i class="fa fa-xmark"></i>
        </div>
        <div class="col-md-12">
          <div class="left_content">
            <h2>Grab airport parking deals up to 75% off for your next holiday by joining our mailing list!</h2>
          </div>
        </div>
        <div class="col-md-12">
          <div class="subscribe-section">
            <form>
              <input class="form-control" type="text" placeholder="Your Name(Only Letters)" name="name" v-model="form.name">
              <input class="form-control" type="text" placeholder="youremail@host.com" name="email" v-model="form.email">
              <input class="btn" type="button" name="" value="JOIN" v-on:click="subscribeNewsletter()">
            </form>
            <p>You can unsubscribe at any time. For more information, please see our <a href="/privacy-policy">Privacy Policy.</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  name: "AppPopup",
  data() {
    return {
      form: {
        'email': '',
        'name': ''
      },
    }
  },
  watch: {
    
  },
  mounted() {
    let appPopup = localStorage.getItem('app_popup');
    if(appPopup == null){
      setTimeout(this.slideIn,3000)
    }
  },
  methods: {
    subscribeNewsletter() {
      let ev  = this;

      this.axios.post(this.apiURL + '/home/subscribe', this.form, {
        headers: {}
      })
          .then(function (response) {
            let res = response.data;
            if (res.status == 1) {
              /*toast({
                title: 'Email subscribed successfully',
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  title: "blackToast-title",
                },
              });*/
              alert('Email subscribed successfully')
              ev.closeAppPopup()
              ev.form.email = '';
              ev.form.name = '';
            } else {
              alert(res.message)
              /*toast({
                title: res.message,
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  title: "blackToast-title",
                },
              });*/
            }

          })
          .catch(function (error) {
            console.log(error);
          });


    },
    slideIn() {
      this.$refs.slideForm.style.left = 0 + 'px';
    },
    closeAppPopup() {
      this.$refs.slideForm.style.left = -450 + 'px';
      localStorage.setItem('app_popup',1)
    }
  }
}
</script>

<style scoped>

</style>