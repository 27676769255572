<template>
  <!-- HEADER AREA -->
  <header class="header no-print">
    <div class="container">
      <div class="row">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link to="/"  class="navbar-brand d-lg-none" ><img style="aspect-ratio: auto 237/50" src="/images/logo.png" alt="main-logo" title="Compare Parking Prices"></router-link>
          <button class="navbar-toggler no-print" type="button" data-bs-toggle="collapse" data-bs-target="#navbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbar">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link" >Home</router-link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Airport Parking</a>
                <div class="dropdown-menu" aria-labelledby="dropdown01">
                  <router-link to="/airport/luton" class="dropdown-item" >Luton</router-link>
                  <router-link to="/airport/birmingham" class="dropdown-item" >Birmingham</router-link>
                  <router-link to="/airport/manchester" class="dropdown-item" >Manchester</router-link>
                  <router-link to="/airport/heathrow" class="dropdown-item" >Heathrow</router-link>
                  <router-link to="/airport/gatwick" class="dropdown-item" >Gatwick</router-link>
                  <router-link to="/airport/stansted" class="dropdown-item" >Stansted</router-link>
                </div>
              </li>
              <li class="nav-item">
                <router-link to="/my-booking" class="nav-link">My Booking</router-link>
              </li>
              <router-link to="/" class="d-none d-lg-block logo_middle"><img style="aspect-ratio: auto 237/50" src="/images/logo.png"></router-link>
              <li class="nav-item">
                <router-link to="/faqs" class="nav-link">FAQs</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="blogURL">Blog</a>
              </li>
              <li class="nav-item">
                <router-link to="/contact-us" class="nav-link">Contact Us</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>

    
  </header>
  <!-- HEADER AREA -->
</template>

<script>
export default {
  name: "AppHeader",
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        document.querySelector('.navbar-collapse.collapse').classList.remove('show');
        if(to.name == "MyBookingDetail")
        {
          document.querySelector('header').classList.add('d-none')
        }else{
          document.querySelector('header').classList.remove('d-none')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>